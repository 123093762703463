/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FFFFFF;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /**font-size: calc(10px + 2vmin);**/
  font-family: 'Quicksand', sans-serif;
}

.App-link {
  color: #61dafb;
}

.App-text-bold-black{
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  margin-top: 8px;
  color: #111827;
}

.App-text-500-black{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  margin-top: 17px;
  color: #707070;
}

.App-text-normal-14{
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  color: #374151;
}
.App-text-color-18{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #1D4999;
}

.App-text-color-15{
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #1D4999;
}
.App-text-datos-13{
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #000000;
}
.App-text-datos-22-bold-black{
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
}
.App-text-color-18-normal{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #374151;
}
hr{
  mix-blend-mode: normal;
  opacity: 0.2;
  border: 1px dashed #000000;
}
.App-text-color-15-input{
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #1D4999;
  margin: 0;
  margin-bottom: 4;
}
input{
  background: #F5F5F5;
  border-radius: 10px;
  box-shadow: none;
  height: 40px;
  width: 90%;
  border: 0;
  padding-left: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #707070;
  font-family: 'Quicksand', sans-serif;
}
input[type="checkbox"] {
  background: #F5F5F5;
  border-radius: 10px;
  box-shadow: none;
  width: 15.34px;
  height: 15.34px;
  border: 0;
  padding-left: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #707070;
  font-family: 'Quicksand', sans-serif;
}
.App-text-datos-12{
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #707070;
  margin: 0;
}
.btn-rechazar{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 35.5px;
  border: 0;
  background: #F59E93;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #1E1E1E;
  font-family: 'Quicksand', sans-serif;
  cursor: pointer;
}
.btn-aceptar{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 35.5px;
  border: 0;
  background: #67DBA2;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;
  font-family: 'Quicksand', sans-serif;
  cursor: pointer;
}
.App-header-line{
  height: 7px;
  background: #1D4999;
}
.App-header-desktop{
  height: 99px;

  background: #F5F5F5;
}

.div_botones {
  padding: 0px 32px;
position: absolute;
bottom: 45px;
width: 100%;
box-sizing: border-box;
}
.btn_enviar {
  color: #fff;
  border-radius: 10px;
  border: 1px solid #1D4999;
  background-color: #1D4999;
  padding: 13px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
  font-family: 'Quicksand', sans-serif;
}
.btn_cancelar {
  color: #1D4999;
  border-radius: 10px;
  border: 1px solid #1D4999;
  background-color: #fff;
  padding: 13px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Quicksand', sans-serif;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
